<template>
  <div class="product-create create-form">
    <v-form
      ref="adjustmentForm"
      v-model="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit(1)"
    >
      <v-row>
        <v-col cols="12">
          <v-container fluid class="white-background main-create-page">
            <div class="p-5 pb-0">
              <v-row>
                <v-col cols="6" class="pt-0">
                  <h1 class="form-title d-flex">
                    <template v-if="adjustmentId">Update</template>
                    <template v-else>Create</template> Adjustment
                  </h1>
                </v-col>
                <v-col cols="6" class="pt-0 text-right">
                  <v-btn
                    :disabled="!formValid || formLoading"
                    :loading="formLoading"
                    class="mx-2 custom-bold-button text-white"
                    color="cyan"
                    v-on:click="onSubmit(1)"
                  >
                    Save as Draft
                  </v-btn>
                  <v-btn
                    :disabled="!formValid || formLoading"
                    :loading="formLoading"
                    class="mx-2 custom-bold-button text-white"
                    color="cyan"
                    v-on:click="onSubmit(3)"
                  >
                    Approve and Adjust
                  </v-btn>
                  <v-btn
                    v-on:click="goBack"
                    :disabled="formLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                    outlined
                    >Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-container fluid>
              <!-- <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 65vh; position: relative"
              > -->
              <v-row>
                <v-col md="6">
                  <table class="width-100 font-size-16">
                    <tr>
                      <td class="py-2 font-weight-600" width="200">
                        <label>Reference #</label>
                      </td>
                      <td class="py-2">
                        <v-text-field
                          v-model="adjustment.reference"
                          dense
                          filled
                          flat
                          label="Reference #"
                          hide-details
                          solo
                          :rules="[
                            validateRules.minLength(
                              adjustment.reference,
                              'Reference #',
                              2
                            ),
                            validateRules.maxLength(
                              adjustment.reference,
                              'Reference #',
                              100
                            ),
                          ]"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          color="cyan"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 font-weight-600" width="200">
                        <label class="required">Date</label>
                      </td>
                      <td class="py-2">
                        <DatePicker
                          solo
                          mandatory
                          v-on:update:date-picker="updateDate"
                          :pageLoading.sync="pageLoading"
                        ></DatePicker>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 font-weight-600" width="200">
                        <label class="required">Reason</label>
                      </td>
                      <td class="py-2">
                        <v-text-field
                          v-model="adjustment.reason"
                          dense
                          filled
                          flat
                          label="Reason"
                          hide-details
                          solo
                          :rules="[
                            validateRules.required(adjustment.reason, 'Reason'),
                            validateRules.minLength(
                              adjustment.reason,
                              'Reason',
                              2
                            ),
                            validateRules.maxLength(
                              adjustment.reason,
                              'Reason',
                              255
                            ),
                          ]"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          color="cyan"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="py-2 font-weight-600"
                        width="200"
                        style="vertical-align: text-bottom"
                      >
                        Description
                      </td>
                      <td class="py-2">
                        <v-textarea
                          v-model="adjustment.description"
                          auto-grow
                          dense
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          color="cyan"
                          label="Description"
                          solo
                          flat
                          :rules="[
                            validateRules.minLength(
                              adjustment.description,
                              'Description',
                              2
                            ),
                            validateRules.maxLength(
                              adjustment.description,
                              'Description',
                              1024
                            ),
                          ]"
                          row-height="25"
                          hide-details
                        ></v-textarea>
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col md="11" class="custom-line-item-template">
                  <table class="custom-border-top width-100">
                    <thead>
                      <tr>
                        <th colspan="2" width="500" class="font-size-16 py-2">
                          Product
                        </th>
                        <th class="pl-5 font-size-16 py-2">
                          Quantity Available
                        </th>
                        <th class="pl-5 font-size-16 py-2">
                          New Quantity On Hand
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon class="mx-2" v-bind="attrs" v-on="on"
                                >mdi-progress-question</v-icon
                              >
                            </template>
                            <span>Maximum allowed Qty is 9999</span>
                          </v-tooltip>
                        </th>
                        <th class="pl-5 font-size-16 py-2">
                          Quantity Adjusted
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon class="mx-2" v-bind="attrs" v-on="on"
                                >mdi-progress-question</v-icon
                              >
                            </template>
                            <span>Maximum allowed Qty is 9999</span>
                          </v-tooltip>
                        </th>
                      </tr>
                      <tr>
                        <td
                          colspan="5"
                          class="custom-border-bottom first-border"
                        ></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="custom-line-item"
                        v-for="(item, index) in adjustmentItems"
                        :key="index"
                      >
                        <td class="pt-4">
                          <v-img
                            :lazy-src="$defaultImage"
                            :src="item.image"
                            width="100"
                            class="margin-auto"
                          >
                          </v-img>
                        </td>
                        <td
                          class="pl-5 pt-4"
                          :class="{ 'pb-4': !adjustmentItems[index + 1] }"
                        >
                          <v-autocomplete
                            v-model="item.product"
                            clearable
                            :items="lineItemList"
                            hide-details
                            dense
                            filled
                            flat
                            label="Search..."
                            solo
                            :rules="[
                              validateRules.required(item.product, 'Product'),
                            ]"
                            item-color="cyan"
                            color="cyan"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            item-text="name"
                            item-value="id"
                            v-on:change="updateLineItem(item.product, index)"
                            class="remove-border-radius hide-border-bottom"
                            return-object
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title
                                  v-html="'Nothing Found.'"
                                ></v-list-item-title>
                              </v-list-item>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-avatar
                                color="cyan"
                                class="headline font-weight-light white--text text-uppercase"
                              >
                                {{ item.name.charAt(0) }}
                              </v-list-item-avatar>
                              <v-list-item-content class="max-width-350px">
                                <v-list-item-title
                                  class="font-size-16 text-ellipsis pb-1 text-capitalize"
                                  v-text="item.name"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                  v-if="item.description"
                                  class="font-size-14 text-ellipsis pb-1"
                                  v-text="item.description"
                                ></v-list-item-subtitle>
                                <v-list-item-subtitle class="font-size-14 pb-1"
                                  ><span class="font-weight-500"
                                    >Selling Cost:</span
                                  >
                                  {{
                                    formatNumber(item.selling_cost)
                                  }}</v-list-item-subtitle
                                >
                                <v-list-item-subtitle class="font-size-14"
                                  ><span class="font-weight-500"
                                    >Available Stock:</span
                                  >
                                  {{
                                    accountingUnFormat(item.available_stock)
                                  }}</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                          <v-textarea
                            v-model="item.description"
                            auto-grow
                            dense
                            filled
                            flat
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            label="Description"
                            solo
                            color="cyan"
                            class="remove-border-radius"
                            hide-details
                            row-height="20"
                            :rules="[
                              validateRules.minLength(
                                item.description,
                                'Description',
                                2
                              ),
                              validateRules.maxLength(
                                item.description,
                                'Description',
                                1024
                              ),
                            ]"
                          ></v-textarea>
                        </td>
                        <td class="pl-5 pt-4">
                          <v-text-field
                            v-model.number="item.available_qty"
                            dense
                            filled
                            flat
                            color="cyan"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            label="Quantity Available"
                            type="number"
                            min="0"
                            max="9999"
                            hide-details
                            solo
                            readonly
                            class="remove-border-radius"
                          ></v-text-field>
                        </td>
                        <td class="pl-5 pt-4">
                          <v-text-field
                            v-model.number="item.stock_on_hand"
                            dense
                            filled
                            flat
                            :ref="'stock_on_hand_' + index"
                            color="cyan"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            label="New Quantity On Hand"
                            type="number"
                            max="9999"
                            hide-details
                            solo
                            :rules="[
                              validateRules.required(
                                item.stock_on_hand,
                                'New Quantity On Hand'
                              ),
                            ]"
                            class="remove-border-radius"
                            v-on:keyup="
                              fixQuantity(
                                item.stock_on_hand,
                                index,
                                'stock_on_hand'
                              )
                            "
                          ></v-text-field>
                        </td>
                        <td class="pl-5 pt-4">
                          <v-text-field
                            v-model.number="item.adjusted_qty"
                            dense
                            filled
                            flat
                            color="cyan"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            label="Quantity Adjusted"
                            type="number"
                            max="9999"
                            hide-details
                            solo
                            :rules="[
                              validateRules.required(
                                item.adjusted_qty,
                                'Quantity Adjusted'
                              ),
                            ]"
                            class="remove-border-radius"
                            v-on:keyup="
                              fixQuantity(
                                item.adjusted_qty,
                                index,
                                'adjusted_qty'
                              )
                            "
                          ></v-text-field>
                          <template v-if="adjustmentItems.length > 1">
                            <div class="text-right">
                              <v-btn
                                tile
                                :disabled="pageLoading"
                                class="my-2 custom-bold-button"
                                color="red"
                                outlined
                                small
                                v-on:click="removeLineItem(index, item.id)"
                                ><strong>Delete</strong>
                              </v-btn>
                            </div>
                          </template>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!$route.query.product">
                      <tr>
                        <td colspan="5" class="custom-border-top">
                          <div>
                            <v-btn
                              :disabled="pageLoading"
                              class="mx-2 my-2 custom-bold-button white--text"
                              color="cyan"
                              v-on:click="addLineItem"
                              ><v-icon small left>mdi-format-align-left</v-icon>
                              Add Line Item
                            </v-btn>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </v-col>
              </v-row>
              <!-- </perfect-scrollbar> -->
            </v-container>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { POST, GET, DELETE, PUT } from "@/core/services/store/request.module";
import DatePicker from "@/view/pages/partials/Datepicker";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "product-adjustment-create",
  data() {
    return {
      adjustment: {
        reference: null,
        date: null,
        reason: null,
        description: null,
      },
      pageLoading: false,
      adjustmentId: null,
      lineItemList: [],
      adjustmentItems: [
        {
          id: null,
          image: null,
          batch: null,
          product: null,
          product_id: null,
          description: null,
          available_qty: null,
          stock_on_hand: null,
          adjusted_qty: null,
        },
      ],
    };
  },
  components: {
    DatePicker,
  },
  methods: {
    updateDate(date) {
      this.adjustment.date = date;
    },
    setProductImage(index) {
      try {
        let product_images = this.adjustmentItems[index].product.product_image;
        if (product_images) {
          this.adjustmentItems[index].image = product_images.file.url;
        }
      } catch (error) {
        this.logError(error);
        this.adjustmentItems[index].image = null;
      }
    },
    fixQuantity(value, index, type) {
      if (type == "stock_on_hand") {
        this.adjustmentItems[index].adjusted_qty =
          this.lodash.toSafeInteger(value) -
          this.lodash.toSafeInteger(this.adjustmentItems[index].available_qty);
      } else if (type == "adjusted_qty") {
        this.adjustmentItems[index].stock_on_hand =
          this.lodash.toSafeInteger(value) +
          this.lodash.toSafeInteger(this.adjustmentItems[index].available_qty);
      }
    },
    removeLineItem(index, id) {
      const _this = this;
      if (id > 0) {
        _this.$store
          .dispatch(DELETE, { url: "line-item/" + id })
          .then(() => {
            _this.adjustmentItems.splice(index, 1);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.adjustmentItems.splice(index, 1);
      }
    },
    addLineItem() {
      this.adjustmentItems.push({
        id: null,
        image: null,
        batch: null,
        product: null,
        product_id: null,
        description: null,
        available_qty: null,
        stock_on_hand: null,
        adjusted_qty: null,
      });
    },
    updateLineItem(product, index) {
      const _this = this;
      try {
        if (_this.lodash.isEmpty(product) === false) {
          _this.adjustmentItems[index].product = product;
          _this.adjustmentItems[index].batch = product.default_batch.id;
          _this.adjustmentItems[index].available_qty = product.available_stock;
          _this.adjustmentItems[index].description = product.description;
          _this.adjustmentItems[index].product_id = product.id;
          let focusElement = _this.$refs[`stock_on_hand_${index}`];
          if (focusElement[0]) {
            _this.$nextTick(() => focusElement[0].focus());
          }
          _this.setProductImage(index);
        } else {
          _this.clearLineItem(index);
        }
      } catch (error) {
        _this.clearLineItem(index);
        _this.logError(error);
      }
    },
    clearLineItem(index) {
      this.adjustmentItems[index] = new Object({
        id: null,
        image: null,
        batch: null,
        product: null,
        product_id: null,
        description: null,
        available_qty: null,
        stock_on_hand: null,
        adjusted_qty: null,
      });
    },
    getLineItems() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "product/get-line-products" })
        .then((response) => {
          _this.lineItemList = response.data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.setDefaultProduct();
        });
    },
    createAdjustment() {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(POST, {
            url: "product/adjustment",
            data: _this.adjustment,
          })
          .then(({ data }) => {
            resolve(data.id);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    createAdjustmentLineItem() {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(PUT, {
            url: "product/adjustment/" + _this.adjustmentId + "/line-item",
            data: { line_items: _this.adjustmentItems },
          })
          .then(({ data }) => {
            resolve(data.id);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async onSubmit(status) {
      const _this = this;
      try {
        if (!_this.$refs.adjustmentForm.validate()) {
          return false;
        }

        _this.formLoading = true;
        _this.adjustment.status = _this.lodash.toSafeInteger(status);
        if (!_this.adjustmentId) {
          _this.adjustmentId = await _this.createAdjustment();
        }

        await _this.createAdjustmentLineItem();

        _this.formLoading = false;

        _this.$router.go(-1);
      } catch (error) {
        _this.formLoading = false;
        _this.logError(error);
      }
    },
    setDefaultProduct() {
      const _this = this;
      let product = _this.lodash.toSafeInteger(_this.$route.query.product);
      let index = _this.lodash.findIndex(_this.lineItemList, function (row) {
        return (row.id = product);
      });
      if (
        index >= 0 &&
        _this.lodash.isEmpty(_this.lineItemList[index]) === false
      ) {
        _this.updateLineItem(_this.lineItemList[index], 0);
      }
    },
  },
  mounted() {
    const _this = this;
    _this.getLineItems();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Product", route: "product" },
      { title: "Adjustment" },
      { title: "Create" },
    ]);
  },
};
</script>
